import { useGetCompanyAllQuery } from '@/hooks/company.hook'
import { reformImage } from '@/utils/imageHandler'
import { Spin } from 'antd'
import { FC } from 'react'
import { CiImageOff } from 'react-icons/ci'

interface IProps {
	className?: string
}

const LogotypeWithoutLink: FC<IProps> = ({ className }) => {
	const { data, isSuccess, isLoading } = useGetCompanyAllQuery()

	if (isLoading) {
		return <Spin className='w-14 h-14' />
	}

	if (isSuccess && data.logo) {
		const logoSrc = reformImage({ PhotoBytea: data.logo })
		return <img className={className} src={logoSrc} alt='logotype' />
	}

	return (
		<div className='h-full flex items-center'>
			<CiImageOff className='w-14 h-14 text-white' />
		</div>
	)
}

export default LogotypeWithoutLink
